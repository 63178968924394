


nav{
    z-index: 100;
    position: fixed;
    background-color: var(--wrapper-background-color);
    color: var(--main-font-color);
    background-image: url(./bg/wrapper/white-vertical-lines.jpg);
    background-size: auto;
    background-repeat: repeat;
    background-attachment: fixed;
    /* -webkit-box-shadow: inset 0px -19px 15px -26px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px -19px 15px -26px rgba(255,255,255,1);
    box-shadow: inset 0px -19px 15px -26px rgba(255,255,255,1); */
    border-bottom: 1px rgb(177, 177, 177) dotted;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.navWrapper{
    width: 1275px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconCreator{
    width: 65px;
    height: 65px;
    color: grey;
    text-decoration: none;
    overflow: hidden;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo img{
    margin-left: 15px;
    width: 40px;
    height: 40px;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo a{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--main-font-color)
}