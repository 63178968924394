/* PrivacyPolicy.css */
.privacy-policy-container {
   
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 20px;
    background-color: var(--wrapper-background-color);
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    border: 1px grey dotted;
}

.privacy-policy-title {
    text-align: center;
    font-size: 2em;
    color: #333;
}

h2 {
    color: #555;
    margin-top: 1.5em;
}

p {
    color: #666;
    margin-bottom: 1em;
}



ul li {
    margin-bottom: 0.5em;
    color: #444;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
