*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto; 
    overflow-x: hidden;
}



@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.app{
    background: rgb(255,245,234);
    background: linear-gradient(180deg, rgba(255,245,234,1) 29%, rgba(255,255,255,1) 49%, rgba(255,245,234,1) 100%);
    background-image: url(./bg/main-bg2.jpg);
    background-size: cover;
    background-position: center center;
}

:root{
    
    --main-font-color: rgb(17, 14, 14);
    --wrapper-background-color: #EFEFEF;
    --category-icon-color: rgb(211, 211, 211);
    --font-button-color: rgb(255, 255, 255);
}




.result-block h1{
    color: rgb(211, 211, 211);
}

p.review-question{
    color: rgb(196, 196, 196);
}

