
.category-page{
    margin: 0 auto;
}



.tests ul li img{
    width: 75px;
    background-color: var(--category-icon-color);
    padding: 5px;
    border-radius: 5%;
}


.tests ul li{
    max-width: 100%;
    width: 400px;
    height: 250px;
    /* background: linear-gradient(to bottom, var(--nav-color), #000000); */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-size: cover; 
   
}


.tests a{
    text-decoration: none;
}


.tests ul li p {
    background-color: rgba(0, 0, 0, 0.65);
    color: var(--font-button-color);
    font-size: 20px; /* alebo podľa potreby */
    width: 400px;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}













.backWrapper{
    padding: 0 40px;
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backWrapper a{
    text-decoration: none;
}

.back{
    font-weight: 800;
    font-size: 20px;
    color: rgb(17, 14, 14);
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* 
.tests ul li:nth-child(){
    
   
} */









.tests h1{
    color: var(--main-font-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 3px;
}



.wrapper .tests ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start ;
    flex-wrap: wrap;
    list-style-type: none;
}


.wrapper .tests ul li{
    color: var(--main-font-color);
    margin: 5px;  

}

