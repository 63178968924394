.wrapper{
    margin: 0 auto; 
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 1276px;
    width: 100%;
    background-color: var(--wrapper-background-color);
    /* background-image: url(./bg/wrapper/white-vertical-lines.jpg);
    background-size: cover; */
    padding: 0 12px;
    padding-bottom: 50px;
    border: 1px rgb(177, 177, 177) dotted;
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}





.categories ul li img{
    width: 75px;
    background-color: var(--category-icon-color);
    padding: 5px;
    border-radius: 5%;
   
}

.categories ul li p{
    width: 400px;
    background-color: rgba(0, 0, 0, 0.65);
    color: var(--font-button-color);
    margin-top: 15px;
    font-size: 1.5rem;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.categories ul li a{
    width: 100%;
    height: 100%;
    display: flex;
    text-decoration: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories ul li{
    width: 400px;
    height: 250px;
    /* background: linear-gradient(to bottom, var(--nav-color), #000000); */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
}







.categories ul li:nth-child(1){
    background-image: url(./img/buttons-bg/it-button.webp);
   
}

.categories ul li:nth-child(2){
    background-image: url(./img/buttons-bg/geography-button.webp);
}

.categories ul li:nth-child(3){
    background-image: url(./img/buttons-bg/economy-button.webp);
}

.categories ul li:nth-child(4){
    background-image: url(./img/buttons-bg/human-button.webp);
}


.categories ul li:nth-child(5){
    background-image: url(./img/buttons-bg/technology-button.webp);
}

.categories ul li:nth-child(6){
    background-image: url(./img/buttons-bg/science-button.webp);
}

.categories ul li:nth-child(7){
    background-image: url(./img/buttons-bg/history-button.webp);
}

.categories ul li:nth-child(8){
    background-image: url(./img/buttons-bg/math-button.webp);
}

.categories ul li:nth-child(9){
    background-image: url(./img/buttons-bg/chem-button.webp);
}

.categories ul li:nth-child(10){
    background-image: url(./img/buttons-bg/psychology-button.jpg);   
}

.categories ul li:nth-child(11){
    background-image: url(./img/buttons-bg/physics-button.jpg);
}


.categories ul li:nth-child(12){
  background-image: url(./img/buttons-bg//lang-button.jpg);  
} 





.categories h1{
    color: var(--main-font-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 3px;
}



.wrapper .categories ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start ;
    flex-wrap: wrap;
    list-style-type: none;
}


.wrapper .categories ul li{
    color: var(--main-font-color);
    margin: 5px;
   
   
}



