.test-wrapper {
    
    
    width: 1265px;
    max-width: 95%;
    margin: 0 auto;
    margin-top: 75px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
   
    
}

.result-block h1{
    display: block;
    border-bottom: 1px grey dotted;
    margin-bottom: 30px;
    padding: 5px;
    color: rgb(53, 53, 53);
    text-align: center;
    
}

.test-details {
    width: 800px;
    max-width: 100%;
    background-color: var(--wrapper-background-color);
    background-image: url(../bg/wrapper/white-lines-test.jpg);
    background-size: cover;
    padding: 50px 20px;
    border: 1px grey dotted;
    border-radius: 10px;
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    
}


.test-details h2{
    color: rgb(75, 75, 75);

}

.test-image {
    width: 100%;
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    margin-bottom: 20px;
    border-radius: 10px;
    object-fit: cover;
    aspect-ratio: 16 / 9;
}

.question-block {
    min-height: 300px;
}

.question-block p {
    color: rgb(199, 199, 199);
}

.question-block ul{
    color: rgb(218, 218, 218);
    margin-bottom: 30px;
}

.question-block ul li{
    
    cursor: pointer;
    margin: 10px 0;
    padding: 15px 20px;
    border: 1px grey dotted;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.result-block h1{
    color: rgb(29, 29, 29);
}


.selected {
    background-color: #d3d3d3; /* Farba pozadia označenej odpovede */
    border: 2px solid #000;   /* Olemovanie označenej odpovede */
    color: black;
}



.correctAnswer{
    visibility: hidden;
}

.question{
    font-size: 20px;
    border: 1px grey dotted;
    padding: 10px 20px;
    background-color: rgb(0, 43, 124);
    border-radius: 5px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
}






.navigation-buttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navigation-buttons button{
    cursor: pointer;
    width: 200px;
    height: 50px;
    text-align: center;
    padding: 5px 10px;
    margin-bottom: 15px;
    font-size: 15px;
    overflow: hidden;
    text-transform: uppercase;
    color: black;
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    border-radius: 5px;
    background-color: var(--wrapper-background-color);
    
}












/* STAVOVY GRAF */



.progress-bar {
    width: 100%;
    background-color: #e0e0df;
    border-radius: 25px;
    margin: 20px 0;
    height: 25px;
    overflow: hidden;
    
}

.progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    text-align: center;
    line-height: 25px;
    color: white;
    border-radius: 25px;
    transition: width 0.2s linear;  /* Plynulá animácia šírky */
}

.question-number {
    color: rgb(75, 75, 75);
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
}



/* CSS pre zvýraznenie správnych a nesprávnych odpovedí: */

.correct-answer {
    background-color: #0d912c; /* Zelená pre správne odpovede */
    color: #ffffff;
    padding: 5px;
    margin: 5px 0;
}

.incorrect-answer {
    background-color: #ff4d4d; /* Červená pre nesprávne odpovede */
    color: #505050;
    padding: 5px;
    margin: 5px 0;
}

.question-review {
    margin-bottom: 20px;
}  



.question-review p{
    color: rgb(61, 61, 61);
}


.review-question {
    font-weight: bold;
    margin-bottom: 10px;
}


.question-review{
    border: 1px rgb(99, 99, 99) dotted;
    margin: 10px 0;
    margin-bottom: 30px;
    padding: 5px;
}


/* TestDetailPage.css */

.question-block {
    transition: opacity 0.3s linear;
    opacity: 1;
}

.question-block ul li {
    color: rgb(75, 75, 75);
    font-weight: 800;
    transition: 0.2s ease all;
    -webkit-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    box-shadow: 0px 17px 9px -14px rgba(0,0,0,0.3);
    
}





.question-block.hidden {
    opacity: 0;
}
